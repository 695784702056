import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import Button, { makeButtonClasses } from "theme/components/atoms/Button";
import deprecateSplittedBooleanEnumProperty from "web/core/dx/deprecateSplittedBooleanEnumProperty";
import { usePreloadProps } from "theme/modules/Router/usePreload";

const isFullLink = /^(https?:)?\/\//;
const isMail = /^(mailto:)/;
const isTel = /^(tel:)/;
const isAsset =
  /\.(ico|jpg|jpeg|png|gif|eot|otf|webp|ttf|woff|woff2|mp4|webm|wav|mp3|m4a|aac|oga|pdf|docx|doc|xls|xlsx|zip)(\?.*)?$/;

const isExternalLink = (target) =>
  typeof target === "string" &&
  (!target || isFullLink.test(target) || isAsset.test(target));

const isNormalLink = (target) =>
  typeof target === "string" && (isMail.test(target) || isTel.test(target));

const RouteLink = ({ to, className, children, ...rest }) => {
  const preloadProps = usePreloadProps({
    to: to,
    ...rest,
  });
  return (
    <NavLink
      {...rest}
      className={className}
      activeClassName="link--active"
      to={to}
      {...preloadProps}
    >
      {children}
    </NavLink>
  );
};

const LinkComponent = ({
  to,
  children,
  external,
  appearance,
  clickType,
  buttonAppearance,
  primary,
  related,
  buttonSize,
  ...rest
}) => {
  if (!buttonAppearance) {
    buttonAppearance = deprecateSplittedBooleanEnumProperty(
      "Link",
      "buttonAppearance",
      [
        {
          oldName: "primary",
          oldValue: primary,
          newValue: "primary",
        },
      ]
    );
  }
  if (!appearance) {
    appearance = deprecateSplittedBooleanEnumProperty("Link", "appearance", [
      {
        oldName: "related",
        oldValue: related,
        newValue: "small",
      },
    ]);
  }

  if (appearance && buttonAppearance) {
    buttonAppearance = undefined;
    if (process.env.NODE_ENV === "development") {
      console.warn(
        `Link property "buttonAppearance" shouldn't be used  when "appearance" is already defined.`
      );
    }
  }

  const classes = classNames("link", {
    [`link--${clickType}`]: clickType,
    [`link--${appearance}`]: !buttonAppearance && appearance,
    [makeButtonClasses(buttonAppearance, buttonSize)]: buttonAppearance,
  });

  return external || isExternalLink(to) ? (
    <a
      {...rest}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={classes}
    >
      {children}
    </a>
  ) : isNormalLink(to) ? (
    <a {...rest} href={to} className={classes}>
      {children}
    </a>
  ) : (
    <RouteLink
      {...rest}
      to={to}
      className={classes}
      activeClassName="link--active"
    >
      {children}
    </RouteLink>
  );
};

LinkComponent.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  children: PropTypes.node.isRequired,
  buttonAppearance: Button.propTypes.appearance,
  buttonSize: Button.propTypes.size,
  clickType: PropTypes.oneOf(["default", "block"]),
  appearance: PropTypes.oneOf(["default", "small", "text", "block", "disabled"]),
  onClick: PropTypes.func,
};

export default LinkComponent;
